<template>
  <div class="space-md">
    <div class="mb-2">
      <b-breadcrumb
        :items="breadcrumbItems"
      />
    </div>
    <TermStudent />
  </div>
</template>

<script>
import {
  BBreadcrumb,
} from 'bootstrap-vue'
import terms from '@/components/basic/term/利用規約.json'
import TermStudent from '@/components/basic/term/components/TermStudent.vue'

export default {
  components: {
    BBreadcrumb,
    TermStudent,
  },
  setup() {
    const breadcrumbItems = [
      {
        text: 'ダッシュボード',
        to: { name: 'student-dashboad' },
        icon: 'HomeIcon',
      },
      {
        text: '受講申込規約',
        active: true,
      },
    ]
    return {
      terms,
      breadcrumbItems,
    }
  },
}
</script>

<style scoped>
</style>
