<template>
  <b-row class="match-height">
    <b-col lg="12">
      <Student class="mb-3" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import Student from '@/components/basic/term/Student.vue'

export default {
  components: {
    BRow,
    BCol,
    Student,
  },
}
</script>
