<template>
  <div class="">
    <div class="title_ text-center mt-3">
      受講申込規約
    </div>
    <div class="mt-2">
      <b-card>
        <div class="termExplain rowSpace">
          この規約は、株式会社美クリエーション（以下、「当社」といいます。）が運営するスクール及びレッスンに関するすべてのサービス（以下、「本サービス」といいます。）に関するものです。本サービスの申込み希望者及び利用者（以下、「スクール生」といいます。）が利用することについて、以下の利用規約（以下、「本規約」といいます、）を定めています。お申込みの前に、必ずこの本規約をご確認のうえ、受講申込みの手続きをしてください。スクール生は本サービスへの申込みを行った時点を持って本規約を承諾したものとみなします。
        </div>
        <div
          v-for="(item, index) in terms"
          :key="index"
          class="rowSpace"
        >
          <div
            v-if="item.title"
            class="termText"
          >
            {{ item.title }}
          </div>
          <div
            v-if="item.subtitle"
            class="termExplain"
          >
            {{ item.subtitle }}
          </div>
          <div
            v-if="item.smallIndex"
            class="termExplain"
          >
            {{ item.smallIndex }}
          </div>
          <div
            v-for="(p, q) in item.explain"
            :key="q"
            class="termExplain"
          >
            <div
              v-if="p.explain && p.type === 1"
              class=""
            >
              {{ p.explain }}
            </div>
            <div v-if="p.small">
              <div
                v-for="(s, t) in p.small"
                :key="t"
                class="termExplain ml-2"
              >
                <span v-if="s.smallExplain">
                  {{ s.smallExplain }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import terms from '@/components/basic/term/利用規約.json'

export default {
  components: {
    BCard,
  },
  setup() {
    return {
      terms,
    }
  },
}
</script>

<style scoped>
.imgStyle_ {
  width: 110%;
  height: 100px;
  object-fit: cover;
  margin: -5% -5% 0 -5%;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .imgStyle_ {
    width: 110%;
    height: 360px;
    object-fit: cover;
    margin: -5% -5% 0 -5%;
  }
}
.title_ {
  font-size: 1.3rem;
  font-weight: 600;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .title_ {
    font-size: 2.2rem;
    font-weight: 600;
  }
}
.termText {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 0 2% 0;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .termText {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 2% 0 3% 0;
  }
}
.termExplain {
  font-size: 0.8rem;
  margin: 1% 0;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .termExplain {
    font-size: 1rem;
    margin: 3% 0;
  }
}
.termsSpace {
  width: 95%;
  margin: 0 auto;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .termsSpace {
    width: 60%;
    margin: 0% auto;
  }
}
.rowSpace {
  margin: 3% 0;
  padding: 0 2%;
}
.underLine {
  border-bottom: solid 0.5px #e6e6e6;
  width: 100%;
  margin: 2% 0 0 0;
}
.explain {
  margin: 1% 0;
}
</style>
